import img1 from '../../img/examples/01.jpg';
import img2 from '../../img/examples/02.jpg';
import img3 from '../../img/examples/03.jpg';
import img4 from '../../img/examples/04.jpg';
import img5 from '../../img/examples/05.jpg';
import img6 from '../../img/examples/06.jpg';
import img7 from '../../img/examples/07.jpg';
import img8 from '../../img/examples/08.jpg';
import img9 from '../../img/examples/09.jpg';
import img10 from '../../img/examples/10.jpg';
import img11 from '../../img/examples/11.jpg';
import img12 from '../../img/examples/12.jpg';

export const dataExamples = [
    {
        img: img1
    },
    {
        img: img2
    },
    {
        img: img3
    },
    {
        img: img4
    },
    {
        img: img5
    },
    {
        img: img6
    },

    {
        img: img7
    },
    {
        img: img8
    },
    {
        img: img9
    },
    {
        img: img10
    },
    {
        img: img11
    },
    {
        img: img12
    }
]