import feedback1 from '../img/feedbacks/01.jpg';
import feedback2 from '../img/feedbacks/02.jpg';
import feedback3 from '../img/feedbacks/03.jpg';
import feedback4 from '../img/feedbacks/04.jpg';
import feedback5 from '../img/feedbacks/05.jpg';
import feedback6 from '../img/feedbacks/06.jpg';
 
 export const dataFeedbacks = [ 
    {
        id: 1,
        feedback:feedback1,
    },
    {
        id: 2,
        feedback:feedback2,
    },
    {
        id: 3,
        feedback:feedback3,
    },
    {
        id: 4,
        feedback:feedback4,
    },    
    {
        id: 5,
        feedback:feedback5,
    },
    {
        id: 6,
        feedback:feedback6,
    }
]

 